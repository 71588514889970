import React from 'react';
import logo from './logo512.png';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const DisputeResolutionPolicy = () => {
  return (
    <div>
     <Link to="/">
        <img src={require('./logo512.png')} className="app-logo" alt="logo" />
      </Link>
      <h1>SaladHR Dispute Resolution Policy</h1>

      <h2>1. Our Commitment to Resolution</h2>
      <p>
        SaladHR is committed to resolving disputes with our users fairly and efficiently. We encourage you to contact us directly if you have any concerns or issues with the Service.
      </p>

      <h2>2. Informal Dispute Resolution</h2>
      <p>
        In the event of a dispute, we encourage you to first contact us directly to try to resolve the issue informally. We will make reasonable efforts to investigate and respond to your concerns in a timely manner.
      </p>

      <h2>3. Mediation</h2>
      <p>
        If informal discussions do not resolve the dispute, we may agree to participate in mediation with a neutral third party to try to reach a mutually agreeable solution.
      </p>

      <h2>4. Binding Arbitration</h2>
      <p>
        If mediation is unsuccessful, or if the parties agree not to pursue mediation, any disputes arising out of or relating to these Terms and Conditions, or your use of the Service, will be finally settled through binding arbitration in accordance with the rules of the [Arbitration Institution Name] (e.g., London Court of International Arbitration). The arbitration will be conducted in [City, Country], and the language of the arbitration will be English. The arbitrator's decision will be final and binding on both parties.
      </p>

      <h2>5. Exceptions to Arbitration</h2>
      <p>
        Notwithstanding the above, we may seek injunctive or other equitable relief in any court of competent jurisdiction to protect our intellectual property rights or confidential information.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These Terms and Conditions, and any disputes arising out of or relating to them, will be governed by the laws of England and Wales, without regard to its conflict of laws provisions.
      </p>

      <h2>7. Severability</h2>
      <p>
        If any provision of this Dispute Resolution Policy is found to be invalid or unenforceable, that provision will be severed from the rest of the Policy, and the remaining provisions will remain in full force and effect.
      </p>

      <h2>8. Entire Agreement</h2>
      <p>
        This Dispute Resolution Policy, together with our Terms and Conditions, constitutes the entire agreement between you and SaladHR regarding the resolution of disputes.
      </p>

      <h2>9. Changes to this Policy</h2>
      <p>
        We may update this Dispute Resolution Policy from time to time. We will notify you of any changes by posting the new Policy on the Website and updating the "Last Updated" date above. Your continued use of the Service after the changes become effective constitutes your acceptance of the updated Policy.
      </p>
      <footer className="footer">
        <p>
          <p>© 2024 SALAD HR TECHNOLOGY LTD </p>
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a> 
        </div>
      </footer>
    </div>
  );
};

export default DisputeResolutionPolicy;