import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import logo from './logo512.png';

const CommunityGuidelines = () => {
  return (
    <div>
      <Link to="/">
        <img src={require('./logo512.png')} className="app-logo" alt="logo" />
      </Link>
      <h1>Community Guidelines for SaladHR</h1>
      <p>
        SaladHR is a community of food lovers and health enthusiasts on a journey to nourish our bodies and minds. We believe that healthy eating should be accessible and enjoyable for everyone. These guidelines are designed to create a welcoming and supportive environment where we can learn, share, and inspire each other to make healthier choices.
      </p>

      <h2>Always Be Supportive</h2>
      <p>
        We are all at different stages in our health journeys. Some of us are just starting, while others have been making healthy choices for years. Be supportive and encouraging of everyone, regardless of where they are on their path. Celebrate each other's successes, big or small, and offer a helping hand when someone is struggling.
      </p>

      <h2>Share Your Knowledge</h2>
      <p>
        We learn best from each other. Share your favorite recipes, tips for meal prepping, or advice for staying motivated. Ask questions when you need help, and offer your own insights when you can. Let's create a space where we can all learn and grow together.
      </p>

      <h2>Respectful Dialogue</h2>
      <p>
        We welcome diverse perspectives and opinions. Remember that what works for one person may not work for another. Engage in respectful dialogue, even when you disagree. Avoid personal attacks, and focus on sharing your own experiences and knowledge in a constructive way.
      </p>

      <h2>Keep It Positive</h2>
      <p>
        We are here to celebrate healthy eating, not to judge or shame each other. Focus on the positive aspects of making healthy choices. Share your successes, offer encouragement, and celebrate the progress you and others are making.
      </p>

      <h2>Respect Privacy</h2>
      <p>
        SaladHR is a place to connect with others who share your passion for healthy eating. However, please be mindful of sharing personal information. Avoid posting private details like your address, phone number, or sensitive medical information.
      </p>

      <h2>Inappropriate Content</h2>
      <p>
        SaladHR is a safe and supportive space for everyone. We do not tolerate any content that is hateful or discriminatory, bullying or harassing, sexually explicit, or spam-like in nature.
      </p>

      <p>
        By following these guidelines, we can create a vibrant and supportive community where we can all learn, grow, and achieve our health goals. Thank you for being a part of SaladHR!
      </p>
      <footer className="footer">
        <p>
          <p>© 2024 SALAD HR TECHNOLOGY LTD </p>
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a> 
        </div>
      </footer>
    </div>
  );
};

export default CommunityGuidelines;
