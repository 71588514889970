import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import './BrandKit.css';

const BrandKit = () => {
  const [expandedSection, setExpandedSection] = useState('');

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  return (
    <div className="brand-kit-container">
      <Link to="/">
        <img src={require('./logo512.png')} className="app-logo" alt="logo" />
      </Link>
      <h1>SaladHR Brand Kit</h1>

      <div className="section">
        <h2 onClick={() => toggleSection('brand-principles')}>
          Brand Principles
          <span className={expandedSection === 'brand-principles' ? 'expanded' : 'collapsed'}>
            {expandedSection === 'brand-principles' ? '▼' : '▶'}
          </span>
        </h2>
        {expandedSection === 'brand-principles' && (
          <div>
            <h3>Why:</h3>
            <p>We get it. Life is busy, and healthy eating often takes a back seat.  We believe everyone deserves the tools and support to make nourishing choices, no matter how hectic their schedule or unique their dietary needs. That's why SaladHR is here – to make healthy eating simple, enjoyable, and personalized for everyone.</p>
            <h3>What:</h3>
            <p>The time-crunched student juggling classes and meal prep? The new parent navigating picky eaters and their own well-being? The food enthusiast seeking inspiration and guidance?  Anyone who wants to prioritize their health without sacrificing joy or convenience. We're the friendly nutrition expert in your pocket, making balanced meals achievable for all.</p>
            <h3>How:</h3>
            <p>We believe healthy eating should spark joy, not dread. We combine cutting-edge AI with a deep understanding of nutrition science to create a meal planning experience that's as fun as it is effective. We embrace diversity and flexibility, recognizing that everyone's path to well-being is unique. Whether you're vegan, gluten-free, or simply curious about a healthier lifestyle, SaladHR is here to guide and inspire you.</p>
            <h3>Our Mission:</h3>
            <p>Our mission is to empower people from all walks of life to embrace healthy eating as a sustainable, joyful part of their daily routine. We envision a world where everyone has access to personalized nutrition guidance, making well-being a reality for all.</p>
            <p><strong>SaladHR: Healthy Eating for Every Body.</strong></p>
          </div>
        )}
      </div>

      {/* Continue adding sections similarly */}
      <div className="section">
        <h2 onClick={() => toggleSection('saladhr-difference')}>
          The SaladHR Difference
          <span className={expandedSection === 'saladhr-difference' ? 'expanded' : 'collapsed'}>
            {expandedSection === 'saladhr-difference' ? '▼' : '▶'}
          </span>
        </h2>
        {expandedSection === 'saladhr-difference' && (
          <div>
            <p>We're the personalized nutrition coach that truly understands you. Unlike generic meal plans or fad diets, SaladHR uses AI to create meal plans tailored to your unique needs, preferences, and goals.</p>
            <p>We make healthy eating fit seamlessly into your real life. We know you're busy, so we prioritize simplicity and flexibility. Our app integrates with your existing routines, making healthy choices the easiest choices, no matter where you are.</p>
            <p>We're more than just meal plans; we're your partner in well-being. We provide ongoing support, education, and inspiration to help you build sustainable habits and thrive.</p>
            <p>We're constantly evolving. As a startup, we're nimble and innovative. We're always listening to our users and leveraging the latest research to enhance our platform and create a truly joyful experience.</p>
            <p>We're committed to making healthy eating accessible to all. We offer a range of affordable options for individuals and businesses, because we believe everyone deserves to feel their best.</p>
          </div>
        )}
      </div>

      <div className="section">
        <h2 onClick={() => toggleSection('saladhr-colors')}>
          SaladHR Brand Colors
          <span className={expandedSection === 'saladhr-colors' ? 'expanded' : 'collapsed'}>
            {expandedSection === 'saladhr-colors' ? '▼' : '▶'}
          </span>
        </h2>
        {expandedSection === 'saladhr-colors' && (
          <div>
            <p>The primary color palette for SaladHR consists of vibrant, fresh colors that evoke energy, vitality, and health:</p>
            <div className="color-palette">
              <div><span className="color-name">Green:</span> <div className="color-box" style={{ backgroundColor: '#3BB143' }}></div></div>
              <div><span className="color-name">Orange:</span> <div className="color-box" style={{ backgroundColor: '#FFA74D' }}></div></div>
              <div><span className="color-name">Yellow:</span> <div className="color-box" style={{ backgroundColor: '#FFD700' }}></div></div>
              <div><span className="color-name">Blue:</span> <div className="color-box" style={{ backgroundColor: '#4F8EF7' }}></div></div>
              <div><span className="color-name">White:</span> <div className="color-box" style={{ backgroundColor: '#FFFFFF' }}></div></div>
              <div><span className="color-name">Black:</span> <div className="color-box" style={{ backgroundColor: '#000000' }}></div></div>
              <div><span className="color-name">Olive Green:</span> <div className="color-box" style={{ backgroundColor: '#698b01' }}></div></div>
              <div><span className="color-name">Dark Gray:</span> <div className="color-box" style={{ backgroundColor: '#4f4f4f' }}></div></div>
              <div><span className="color-name">Light Green:</span> <div className="color-box" style={{ backgroundColor: '#e0efb8' }}></div></div>
              <div><span className="color-name">Off White:</span> <div className="color-box" style={{ backgroundColor: '#fcfcfc' }}></div></div>
            </div>
          </div>
        )}
      </div>

      <div className="section">
        <h2 onClick={() => toggleSection('saladhr-logos')}>
          SaladHR Logos
          <span className={expandedSection === 'saladhr-logos' ? 'expanded' : 'collapsed'}>
            {expandedSection === 'saladhr-logos' ? '▼' : '▶'}
          </span>
        </h2>
        {expandedSection === 'saladhr-logos' && (
          <div className="logo-grid">
            <img src={require('./logos/1.png')} alt="SaladHR Logo" />
            <img src={require('./logos/2.png')} alt="SaladHR Logo" />
            <img src={require('./logos/3.png')} alt="SaladHR Logo" />
            <img src={require('./logos/4.png')} alt="SaladHR Logo" />
            <img src={require('./logos/5.png')} alt="SaladHR Logo" />
            <img src={require('./logos/6.png')} alt="SaladHR Logo" />
            <img src={require('./logos/7.png')} alt="SaladHR Logo" />
            <img src={require('./logos/8.png')} alt="SaladHR Logo" />
            <img src={require('./logos/9.png')} alt="SaladHR Logo" />
            <img src={require('./logos/10.png')} alt="SaladHR Logo" />
          </div>
        )}
      </div>

      {/* Add other sections here */}

      <footer className="footer">
        <p>
          © 2024 SALAD HR TECHNOLOGY LTD 
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a>
        </div>
      </footer>
    </div>
  );
};

export default BrandKit;
