import React from 'react';
import logo from './logo512.png';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const Support = () => {
  return (
    <div className="support-page">
      <Link to="/">
        <img src={logo} className="app-logo" alt="logo" />
      </Link>
      <h1>Contact Support</h1>

      <h2>How to Reach Us</h2>
      <p>If you have any questions or need assistance, please reach out to our support team:</p>
      <ul>
        <li>Email: support@saladhr.com</li>
        <li>Phone: +44 20 7946 0958</li>
      </ul>
      <p>We are here to help you 24/7.</p>

      <footer className="footer">
        <p>© 2024 SALAD HR TECHNOLOGY LTD</p>
        <br />
        <p>
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a>
        </div>
      </footer>
    </div>
  );
};

export default Support;
