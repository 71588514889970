import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BusyMomSurvey.css';
import logo from './logo512.png';

const BusyMomSurvey = () => {
  const [formData, setFormData] = useState({
    childrenCount: '',
    mealPlanningFrequency: '',
    mealPlanningChallenges: [],
    convenientPlantBasedMeals: '',
    helpfulFeatures: [],
    recipeSources: [],
    appUsage: '',
    dietaryPreferences: [],
    connectingInterest: '',
    motivation: [],
    stressReduction: '',
    priceRange: '',
    additionalFeatures: '',
    communityParticipation: '',
    educationalResources: [],
    email: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? (checked ? [...prevData[name], value] : prevData[name].filter(item => item !== value)) : value
    }));
  };

  return (
    <div className="busy-mom-survey">
      <header className="header">
        <Link to="/">
          <img src={logo} className="app-logo" alt="logo" />
        </Link>
      </header>
      <div className="survey-container">
        <h1>Busy Mom Survey</h1>
        <form action="https://formspree.io/f/mleqkwog" method="POST">
          <div className="question">
            <p>1. How many children do you currently live with?</p>
            <label>
              <input type="radio" name="childrenCount" value="1" onChange={handleChange} /> 1
            </label>
            <label>
              <input type="radio" name="childrenCount" value="2" onChange={handleChange} /> 2
            </label>
            <label>
              <input type="radio" name="childrenCount" value="3" onChange={handleChange} /> 3
            </label>
            <label>
              <input type="radio" name="childrenCount" value="4 or more" onChange={handleChange} /> 4 or more
            </label>
          </div>

          <div className="question">
            <p>2. How often do you currently plan meals for your family?</p>
            <select name="mealPlanningFrequency" value={formData.mealPlanningFrequency} onChange={handleChange} required>
              <option value="">Select</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Bi-weekly">Bi-weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>

          <div className="question">
            <p>3. What are your biggest challenges with meal planning? (Select all that apply)</p>
            <label>
              <input type="checkbox" name="mealPlanningChallenges" value="Lack of time" onChange={handleChange} /> Lack of time
            </label>
            <label>
              <input type="checkbox" name="mealPlanningChallenges" value="Finding healthy options" onChange={handleChange} /> Finding healthy options
            </label>
            <label>
              <input type="checkbox" name="mealPlanningChallenges" value="Accommodating dietary restrictions" onChange={handleChange} /> Accommodating dietary restrictions
            </label>
            <label>
              <input type="checkbox" name="mealPlanningChallenges" value="Managing picky eaters" onChange={handleChange} /> Managing picky eaters
            </label>
            <label>
              <input type="checkbox" name="mealPlanningChallenges" value="Budget constraints" onChange={handleChange} /> Budget constraints
            </label>
            <label>
              Other (please specify):
              <input type="text" name="mealPlanningChallenges" onChange={handleChange} />
            </label>
          </div>

          <div className="question">
            <p>4. How important is it for you to find convenient plant-based meal options?</p>
            <label>
              <input type="radio" name="convenientPlantBasedMeals" value="Very important" onChange={handleChange} /> Very important
            </label>
            <label>
              <input type="radio" name="convenientPlantBasedMeals" value="Somewhat important" onChange={handleChange} /> Somewhat important
            </label>
            <label>
              <input type="radio" name="convenientPlantBasedMeals" value="Neutral" onChange={handleChange} /> Neutral
            </label>
            <label>
              <input type="radio" name="convenientPlantBasedMeals" value="Somewhat unimportant" onChange={handleChange} /> Somewhat unimportant
            </label>
            <label>
              <input type="radio" name="convenientPlantBasedMeals" value="Not important at all" onChange={handleChange} /> Not important at all
            </label>
          </div>

          <div className="question">
            <p>5. Which features would be most helpful for you in a meal planning app? (Select all that apply)</p>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Personalized meal plans" onChange={handleChange} /> Personalized meal plans
            </label>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Easy and quick recipes" onChange={handleChange} /> Easy and quick recipes
            </label>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Grocery list generation" onChange={handleChange} /> Grocery list generation
            </label>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Meal prep guides" onChange={handleChange} /> Meal prep guides
            </label>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Community support" onChange={handleChange} /> Community support
            </label>
            <label>
              <input type="checkbox" name="helpfulFeatures" value="Nutritional information" onChange={handleChange} /> Nutritional information
            </label>
            <label>
              Other (please specify):
              <input type="text" name="helpfulFeatures" onChange={handleChange} />
            </label>
          </div>

          <div className="question">
            <p>6. How do you currently find recipes? (Select all that apply)</p>
            <label>
              <input type="checkbox" name="recipeSources" value="Online searches" onChange={handleChange} /> Online searches
            </label>
            <label>
              <input type="checkbox" name="recipeSources" value="Cookbooks" onChange={handleChange} /> Cookbooks
            </label>
            <label>
              <input type="checkbox" name="recipeSources" value="Social media" onChange={handleChange} /> Social media
            </label>
            <label>
              <input type="checkbox" name="recipeSources" value="Friends and family" onChange={handleChange} /> Friends and family
            </label>
            <label>
              <input type="checkbox" name="recipeSources" value="Meal planning apps" onChange={handleChange} /> Meal planning apps
            </label>
            <label>
              Other (please specify):
              <input type="text" name="recipeSources" onChange={handleChange} />
            </label>
          </div>
          <div className="question">
  <p>7. How often do you use mobile apps for meal planning and grocery shopping?</p>
  <label>
    <input type="radio" name="appUsage" value="Daily" onChange={handleChange} /> Daily
  </label>
  <label>
    <input type="radio" name="appUsage" value="Several times a week" onChange={handleChange} /> Several times a week
  </label>
  <label>
    <input type="radio" name="appUsage" value="Weekly" onChange={handleChange} /> Weekly
  </label>
  <label>
    <input type="radio" name="appUsage" value="Monthly" onChange={handleChange} /> Monthly
  </label>
  <label>
    <input type="radio" name="appUsage" value="Rarely/Never" onChange={handleChange} /> Rarely/Never
  </label>
</div>

<div className="question">
  <p>8. What dietary preferences or restrictions do you need to accommodate in your family? (Select all that apply)</p>
  <label>
    <input type="checkbox" name="dietaryPreferences" value="Vegetarian" onChange={handleChange} /> Vegetarian
  </label>
  <label>
    <input type="checkbox" name="dietaryPreferences" value="Vegan" onChange={handleChange} /> Vegan
  </label>
  <label>
    <input type="checkbox" name="dietaryPreferences" value="Gluten-free" onChange={handleChange} /> Gluten-free
  </label>
  <label>
    <input type="checkbox" name="dietaryPreferences" value="Dairy-free" onChange={handleChange} /> Dairy-free
  </label>
  <label>
    <input type="checkbox" name="dietaryPreferences" value="Nut-free" onChange={handleChange} /> Nut-free
  </label>
  <label>
    Other (please specify):
    <input type="text" name="dietaryPreferences" onChange={handleChange} />
  </label>
</div>

<div className="question">
  <p>9. How interested are you in connecting with other moms for meal planning advice and support?</p>
  <label>
    <input type="radio" name="connectingInterest" value="Very interested" onChange={handleChange} /> Very interested
  </label>
  <label>
    <input type="radio" name="connectingInterest" value="Somewhat interested" onChange={handleChange} /> Somewhat interested
  </label>
  <label>
    <input type="radio" name="connectingInterest" value="Neutral" onChange={handleChange} /> Neutral
  </label>
  <label>
    <input type="radio" name="connectingInterest" value="Somewhat uninterested" onChange={handleChange} /> Somewhat uninterested
  </label>
  <label>
    <input type="radio" name="connectingInterest" value="Not interested at all" onChange={handleChange} /> Not interested at all
  </label>
</div>

<div className="question">
  <p>10. What motivates you to prioritize healthy eating for your family? (Select all that apply)</p>
  <label>
    <input type="checkbox" name="motivation" value="Health and well-being" onChange={handleChange} /> Health and well-being
  </label>
  <label>
    <input type="checkbox" name="motivation" value="Environmental concerns" onChange={handleChange} /> Environmental concerns
  </label>
  <label>
    <input type="checkbox" name="motivation" value="Personal values" onChange={handleChange} /> Personal values
  </label>
  <label>
    <input type="checkbox" name="motivation" value="Influence of peers/community" onChange={handleChange} /> Influence of peers/community
  </label>
  <label>
    Other (please specify):
    <input type="text" name="motivation" onChange={handleChange} />
  </label>
</div>

<div className="question">
  <p>11. How can a meal planning app reduce your stress related to meal preparation?</p>
  <textarea name="stressReduction" onChange={handleChange}></textarea>
</div>

<div className="question">
  <p>12. What price range would you consider reasonable for a meal planning subscription service?</p>
  <label>
    <input type="radio" name="priceRange" value="Free" onChange={handleChange} /> Free
  </label>
  <label>
    <input type="radio" name="priceRange" value="£1-5 per month" onChange={handleChange} /> £1-5 per month
  </label>
  <label>
    <input type="radio" name="priceRange" value="£6-10 per month" onChange={handleChange} /> £6-10 per month
  </label>
  <label>
    <input type="radio" name="priceRange" value="£11-15 per month" onChange={handleChange} /> £11-15 per month
  </label>
  <label>
    <input type="radio" name="priceRange" value="£16+ per month" onChange={handleChange} /> £16+ per month
  </label>
</div>

<div className="question">
  <p>13. Any additional features or support you would like to see in a meal planning app?</p>
  <textarea name="additionalFeatures" onChange={handleChange}></textarea>
</div>

<div className="question">
  <p>14. Would you be interested in participating in challenges and sharing your progress with a community of like-minded moms?</p>
  <label>
    <input type="radio" name="communityParticipation" value="Yes" onChange={handleChange} /> Yes
  </label>
  <label>
    <input type="radio" name="communityParticipation" value="No" onChange={handleChange} /> No
  </label>
  <label>
    <input type="radio" name="communityParticipation" value="Maybe" onChange={handleChange} /> Maybe
  </label>
</div>

<div className="question">
  <p>15. What type of educational resources would you find most valuable? (Select all that apply)</p>
  <label>
    <input type="checkbox" name="educationalResources" value="Plant-based nutrition" onChange={handleChange} /> Plant-based nutrition
  </label>
  <label>
    <input type="checkbox" name="educationalResources" value="Cooking techniques" onChange={handleChange} /> Cooking techniques
  </label>
  <label>
    <input type="checkbox" name="educationalResources" value="Healthy eating tips" onChange={handleChange} /> Healthy eating tips
  </label>
  <label>
    <input type="checkbox" name="educationalResources" value="Time-saving hacks" onChange={handleChange} /> Time-saving hacks
  </label>
  <label>
    <input type="checkbox" name="educationalResources" value="Budget-friendly meal ideas" onChange={handleChange} /> Budget-friendly meal ideas
  </label>
  <label>
    Other (please specify):
    <input type="text" name="educationalResources" onChange={handleChange} />
  </label>
</div>

<div className="question">
  <p>16. We plan to run a draw where one lucky mom gets to win a spa trip. The winner will be announced on Monday, July 15th. To opt in for the draw, please share your email:</p>
  <input type="email" name="email" placeholder="Email" onChange={handleChange} />
</div>

<button type="submit">Submit</button>
</form>
</div>
<footer className="footer">
  <p>
    © 2024 SALAD HR TECHNOLOGY LTD
    <br />
    Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
  </p>
  <div>
    <a href="/brand-kit">Brand Kit</a> |{' '}
    <a href="/community-guidelines">Community Guidelines</a> |{' '}
    <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a>
        </div>
      </footer>
    </div>
  );
};

export default BusyMomSurvey;