import React from 'react';
import logo from './logo512.png';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const PrivacyPolicy = () => {
  return (
    <div>
     <Link to="/">
        <img src={require('./logo512.png')} className="app-logo" alt="logo" />
      </Link>
      <h1>Privacy Policy for SaladHR Technology Ltd.</h1>
      <p><strong>Last Revised: May 12, 2024</strong></p>

      <h2>1. Introduction</h2>
      <p>
        At SaladHR Technology Ltd. ("SaladHR", "we", "us", or "our"), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you use our website, mobile application, and other services (collectively, the "Services"). By using our Services, you agree to the collection and use of your information in accordance with this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>We may collect the following types of personal information when you use our Services:</p>
      <ul>
        <li><strong>Account Information:</strong> When you create an account, we may collect your name, email address, and other contact information.</li>
        <li><strong>Dietary Preferences:</strong> We may collect information about your dietary preferences, allergies, and health goals to provide personalized meal recommendations.</li>
        <li><strong>Payment Information:</strong> If you purchase a subscription or other products through our Services, we may collect payment information, such as your credit card number and billing address.</li>
        <li><strong>Usage Data:</strong> We may collect information about how you use our Services, such as the pages you visit, the features you use, and the time and date of your activities.</li>
        <li><strong>Device Information:</strong> We may collect information about the device you use to access our Services, such as your IP address, browser type, and operating system.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We may use your personal information for the following purposes:</p>
      <ul>
        <li><strong>To provide and improve our Services:</strong> We use your information to provide you with personalized meal recommendations, track your progress, and improve the functionality and user experience of our Services.</li>
        <li><strong>To communicate with you:</strong> We may use your information to send you emails or other communications about our Services, such as updates, promotional offers, or newsletters.</li>
        <li><strong>To process payments:</strong> We use your payment information to process transactions for subscriptions or other products you purchase through our Services.</li>
        <li><strong>To protect our rights and interests:</strong> We may use your information to investigate and prevent fraud, abuse, or other illegal activities.</li>
        <li><strong>To comply with legal obligations:</strong> We may use your information to comply with applicable laws and regulations.</li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <p>We may share your personal information with the following third parties:</p>
      <ul>
        <li><strong>Service providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer support.</li>
        <li><strong>Business partners:</strong> We may share your information with business partners who offer products or services that we believe may be of interest to you.</li>
        <li><strong>Other users:</strong> If you choose to share your meal plans or other information with other users through our Services, your information may be visible to those users.</li>
        <li><strong>Legal authorities:</strong> We may share your information with law enforcement or other government agencies if required by law or to protect our rights or the rights of others.</li>
      </ul>

      <h2>5. Your Choices</h2>
      <p>You have the following choices regarding your personal information:</p>
      <ul>
        <li><strong>Access and update your information:</strong> You can access and update your account information at any time by logging into your account settings.</li>
        <li><strong>Opt out of marketing communications:</strong> You can opt out of receiving marketing communications from us by following the instructions in those communications or by contacting us directly.</li>
        <li><strong>Disable cookies:</strong> You can disable cookies through your browser settings, but this may limit your ability to use certain features of our Services.</li>
      </ul>

      <h2>6. Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.
      </p>

      <h2>7. Children's Privacy</h2>
      <p>
        Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
      </p>

      <h2>8. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website or through our Services. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <p>
        admin@saladhr.com
      </p>
      <p>
        SaladHR Technology Ltd.<br />
        Plexel,Here East, Queen Elizabeth Olympic Park<br />
        London, England, E20 3BS
      </p>
      <footer className="footer">
        <p>
          <p>© 2024 SALAD HR TECHNOLOGY LTD </p>
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a> 
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;