import React from 'react';
import logo from './logo512.png';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const TermsOfUse = () => {
  return (
    <div>
     <Link to="/">
        <img src={require('./logo512.png')} className="app-logo" alt="logo" />
      </Link>
      <h1>SaladHR Terms and Conditions of Service</h1>
      <p><strong>Last Updated: 12th May 2024</strong></p>

      <h2>1. Welcome to SaladHR</h2>
      <p>
        These Terms and Conditions govern your use of the SaladHR website, mobile app, and related services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these terms. If you disagree with any part of these terms, please do not use the Service.
      </p>

      <h2>2. Changes to these Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. We'll post a notice on the Website for at least 7 days after any changes. Your continued use of the Service after the changes become effective constitutes your acceptance of the updated terms.
      </p>

      <h2>3. The SaladHR Service</h2>
      <p>
        SaladHR provides meal planning and healthy eating services, including personalized recommendations, tracking tools, and educational resources. We may modify, suspend, or discontinue any part of the Service at any time.
      </p>

      <h2>4. Your Use of the Service</h2>
      <ul>
        <li>You are responsible for your use of the Service and any actions taken using your account.</li>
        <li>You must comply with our Community Guidelines when using the Service.</li>
        <li>To register for an account, you must provide accurate information and keep it updated. You are responsible for the security of your account and any activity under it.</li>
      </ul>

      <h2>5. Content You Submit</h2>
      <p>
        By submitting content to SaladHR, you grant us a perpetual, worldwide, non-exclusive, royalty-free license to use, modify, reproduce, and distribute that content in connection with the Service. You are responsible for ensuring that you have the right to submit the content you provide.
      </p>

      <h2>6. Your Responsibilities</h2>
      <p>
        You agree to use the Service in compliance with all applicable laws and regulations. You will not use the Service for any unlawful or harmful purpose.
      </p>

      <h2>7. No Warranties</h2>
      <p>
        The Service is provided "as is" without any warranties of any kind, express or implied. We do not guarantee that the Service will be uninterrupted, error-free, or secure.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, SaladHR will not be liable for any indirect, incidental, special, consequential, or exemplary damages arising from your use of the Service. Our total liability to you is limited to the amount you paid to SaladHR for the Service in the 12 months before the event giving rise to the claim.
      </p>

      <h2>9. Termination</h2>
      <p>
        We may terminate your access to the Service at any time for any reason.
      </p>

      <h2>10. Intellectual Property</h2>
      <p>
        All content on the Service, including designs, text, graphics, and software, is the property of SaladHR or its licensors. You may not use any of this content without our express permission.
      </p>

      <h2>11. Privacy</h2>
      <p>
        Our Privacy Policy explains how we collect, use, and share your information. By using the Service, you consent to our privacy practices.
      </p>

      <h2>12. Promotions</h2>
      <p>
        Any promotion codes we offer are subject to specific terms and conditions regarding eligibility, expiration, and usage limitations.
      </p>

      <h2>13. Governing Law and Disputes</h2>
      <p>
        These terms are governed by the laws of England and Wales. Any disputes will be resolved through binding arbitration as described in our [Dispute Resolution Policy].
      </p>

      <h2>14. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please contact us at admin@saladhr.com
      </p>

      <footer className="footer">
        <p>
          <p>© 2024 SALAD HR TECHNOLOGY LTD </p>
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a> 
        </div>
      </footer>
    </div>
    
  );
};

export default TermsOfUse;