import React, { useEffect } from 'react';
import logo from './logo512.png';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

const MeetSaladHR = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <Link to="/">
        <img src={logo} className="app-logo" alt="logo" />
      </Link>
      <h1>Meet SaladHR</h1>
      <p>
        Schedule a meeting with our team using the calendar below.
      </p>

      <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/peter-machona?uuid=899611d5-7cce-44d4-b7b1-3a0dedfc40f2&embed=true"></div>

      <footer className="footer">
        <p>
          © 2024 SALAD HR TECHNOLOGY LTD
          <br />
          Plexel, Here East, Queen Elizabeth Olympic Park, London, England, E20 3BS | Company Number: 14979493 | Made with 💚 from London, United Kingdom.
        </p>
        <div>
          <a href="/brand-kit">Brand Kit</a> |{' '}
          <a href="/community-guidelines">Community Guidelines</a> |{' '}
          <a href="/dispute-resolution-policy">Dispute Resolution Policy</a> |{' '}
          <a href="/privacy-policy">Privacy Policy</a> |{' '}
          <a href="/terms-of-use">Terms of Use</a>
        </div>
      </footer>
    </div>
  );
};

export default MeetSaladHR;
